.hero-links {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: $hero-links-bg;
  position: absolute;
  z-index: 999;
  width: 100%;

  ul {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      cursor: pointer;
    }
  }

  button {
    color: $dark;
    font-family: "Gill Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    margin: 0;
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
    @include transition(color ease-out 80ms);

    &:focus,
    &:active {
      outline: none;
    }

    &:hover {
      color: $primary;
    }
  }
}

.mobile {
  margin-top: 60px;
}
