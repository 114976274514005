.img-container {
  overflow: hidden;
  width: 160px;
  height: 96px;
}

.fs-text {
  font-size: 14px;
  margin: 0;
}

.add-sample-button {
  width: fit-content;
  color: $white;
  padding: 0 0.5rem !important;
}
