.description {
  font-family: $font-family-base;
  font-weight: lighter;
  color: $dim-gray;

  a {
    color: $dim-gray;
    font-weight: normal;

    &:hover {
      color: $black;
    }
  }
}
