.form-group {
  label {
    &:not(.form-check-label) {
      font-weight: $font-weight-bold;
    }
    font-size: small;
    letter-spacing: 1px;
  }

  select {
    border-radius: 0;
  }
}

.form-check-input {
  float: none !important;
  margin-top: 0.18rem;
  margin-right: 0.5rem;
}

.form-check-input:checked {
  background-color: gray;
  outline: none;
  border-color: gray;
  box-shadow: none;
}

.form-control {
  border-radius: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-error-message {
  font-size: $font-size-sm;
  margin-top: 0.25rem;
}

.form-error-message--color-red {
  color: $sizzling-red;
}

.form-error-message--color-white {
  color: $white;
}

.form-error-message--weight-bold {
  font-weight: $font-weight-bold;
}

.form-error-message--weight-normal {
  font-weight: $font-weight-normal;
}

.form-check-label--white {
  color: $white;
}
