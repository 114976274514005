.modal-content {
  border-radius: 0.5rem;
}

.modal-header {
  position: relative;

  .btn-close {
    font-size: medium;

    &:focus {
      outline: 0;
      box-shadow: none;
      opacity: 1;
    }
  }
}

.modal-title {
  width: 100%;
}

@include media-breakpoint-down(md) {
  .modal-dialog {
    margin: 2rem;
  }
}
