@include media-breakpoint-down(sm) {
  .footer {
    height: 1.5rem;
  }

  .container-form {
    padding: 0 2rem;
  }

  .desktop {
    display: none;
  }

  .footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    span.small {
      display: none;
    }
  }
}
