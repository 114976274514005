.title {
  font-size: $h1-font-size;
  text-align: center;
  font-weight: bold;
  margin-top: 3rem;
}

.design-title {
  font-size: $h2-font-size;
  font-weight: bold;
  margin: 0 1rem;
}

.font-color {
  color: $dim-gray;
}

.label {
  display: block;
  font-size: 12px;
}

.label-ref {
  line-height: 0.4rem;
}

.image-sample-size {
  width: 200px;
}

.padding-button {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.grow-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  cursor: pointer;
}
.grow-on-hover:hover,
.grow-on-hover:focus,
.grow-on-hover:active {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
