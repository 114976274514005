@use "sass:math";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Type
$font-family-base: "Gill Sans", sans-serif;
$font-family-title: "Dala Moa", sans-serif;

// Colors
$dark-cyan: #04a0a4;
$very-dark-cyan: #046e70;
$cyan-darker: #00575f;
$gray: #4a4a4a;
$gray-light: #777777;
$gray-lighter: #f9f8f5;
$davys-grey: #5e5e5e;
$silver: #c2c2c2;
$skeleton-background: #f1f1f1;
$green: #0c7c62;
$green-light: #8dc63f;
$sizzling-red: #f05d5e;
$gunmetal: #273343;
$platinum: #e6e6e6;

$primary: $very-dark-cyan;
$secondary: $gray-light;
$dark: $black;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

// creating custom classes
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop(
  $utilities-colors,
  rgba-css-var,
  "$key",
  "text"
);
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

// font weight
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-black: 900;

// font size
$font-size-xs: ($font-size-base * 0.75);

// Grid
$grid-extra-small-gutter-width: 8px;
$grid-small-gutter-width: 16px;
$grid-medium-gutter-width: 24px;
$grid-gutter-width-base: $grid-gutter-width !default;
$grid-large-gutter-width: 35px;
$grid-extra-large-gutter-width: 40px;
$grid-gutter-width-half: math.div($grid-gutter-width, 2);

$grid-gutter-widths: (
  extra-small: $grid-extra-small-gutter-width,
  small: $grid-small-gutter-width,
  medium: $grid-medium-gutter-width,
  regular: $grid-gutter-width-base,
  large: $grid-large-gutter-width,
  extra-large: $grid-extra-large-gutter-width,
);

// Sizing
$header-height: 3.75rem;
$footer-height: 3.75rem;
$hero-bg-width-md: 50vw;
$hero-bg-width-lg: 100%;
$hero-bg-width-xl: 100%;

:export {
  headerHeight: $header-height;
}

// for old guide landing linetex eu

$header-height: 54px;
$header-height-md: 100px;

$hero-bg-width-md: 50vw;
$hero-bg-width-lg: 100%;
$hero-bg-width-xl: 100%;

// Colors
$body-color: #111;
$dark: #000;
$white: #fff;
$davys-grey: #5e5e5e;
$dim-gray: #6b6b6b;
$sonic-silver: #777;
$raisin-black: #2c2e3f;
$sizzling-red: #f05d5e;
$skobeloff: #046e71;
$yale-blue: #1f4e91;
$raisin-black-opacity: rgba(44, 46, 63, 0.7);

$primary: $skobeloff;
$secondary: $yale-blue;
$gray: $davys-grey;

// Font weight
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-black: 900;

// Font size
$font-size-xs: ($font-size-base * 0.75);
