@include media-breakpoint-up(lg) {
  .video-container {
    height: 900px;
  }
  .section-container {
    height: 600px;
  }

  .image-content {
    height: 100%;
  }

  .description-container {
    position: relative;
    width: 500px !important;
    left: -300px;
    height: 480px;
  }
}
