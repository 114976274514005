@include media-breakpoint-only(md) {
  .video-container {
    height: 500px;
  }

  .section-container {
    height: fit-content;
  }

  .image-content {
    height: 400px;
  }

  .description-container {
    position: absolute;
    width: 500px !important;
    top: 490px;
    left: 150px;
    height: 480px;
    margin-bottom: 0;
  }
}
