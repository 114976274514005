@include media-breakpoint-down(md) {
  .play-icon {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 30px !important;
    height: 40px !important;
    color: $platinum;
    z-index: 1;
    cursor: pointer;
  }
}
