@include media-breakpoint-up(md) {
  .video-container {
    height: 500px;
  }

  .section-container {
    height: fit-content;
  }

  .image-content {
    height: 400px;
  }

  .description-container {
    position: relative;
    width: 500px !important;
    top: -10px;
    left: 300px;
    height: 480px;
  }
}
