@include media-breakpoint-down(lg) {
  .hero {
    position: relative;
    height: 360px;
    background-image: url("../imgs/hero-mobile.jpg");
    background-position: center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.2);

    &::before {
      content: "";
      height: 360px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
  }

  .hero-description-container {
    margin: 0 2rem;
    margin-bottom: 3rem;
    z-index: 1;

    .description-text {
      font-size: $font-size-sm;
    }
  }

  .design-navigator {
    display: none;
  }

  .play-icon {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px !important;
    height: 64px !important;
  }

  .play-icon-label {
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1;
  }
}
