// Between the resolutions 992px and 1024px, occur some problems, that's why this media query
@media only screen and (min-width: 1025px) {
  .sample-image {
    height: fit-content !important;
  }

  .glass-magnifier > div > img {
    width: 1000px !important;
    height: 1000px !important;
  }
}
