.skeleton-background {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.video-container {
  position: relative;
}

.play-icon {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 96px;
  height: 96px;
  color: $platinum;
  z-index: 1;
  cursor: pointer;
}

.down-line-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.down-line-icon {
  width: 64px;
  height: 64px;
  color: $platinum;
  position: absolute;
  bottom: 12%;
}

.scroll-animation {
  animation: down 1.3s infinite;
  -webkit-animation: down 1.3s infinite;
  &::before {
    content: "";
    transform: rotate(-45deg);
  }
}

@keyframes down {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translateY(0);
  }
}
