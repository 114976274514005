@include media-breakpoint-up(xl) {
  .hero-links {
    &:before {
      width: $hero-bg-width-xl;
    }

    ul {
      li {
        button {
          font-size: 11px;
        }
      }
    }
  }
}