@include media-breakpoint-down(md) {
  .title {
    font-size: $h4-font-size;
    margin-top: 1rem;
  }

  .design-title {
    font-size: $h5-font-size;
    margin: 0 0.5rem;
  }

  .image-sample-size {
    width: 64px;
    height: 64px;
  }

  .label {
    display: block;
    font-size: 0.5rem;
  }
}
