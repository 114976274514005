@include media-breakpoint-down(lg) {
  .pattern-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .title-container {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.5rem 1rem;
    border-radius: 0 0.75rem 0.75rem 0;
  }
}
