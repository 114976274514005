.slider-container {
  padding-top: 4rem;
}

.home-slide {
  position: relative;
  background-color: $skeleton-background;
  background-position: center center;
  background-size: cover;
  // padding-top: $header-height;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.home-slide__content {
  position: relative;
  z-index: 2;
}
