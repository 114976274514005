@include media-breakpoint-up(xl) {
  .video-container {
    height: 900px;
  }
  .section-container {
    height: 600px;
  }

  .image-content {
    height: 100%;
  }

  .description-container {
    position: relative;
    width: 600px !important;
    left: -300px;
    top: -5px;
    height: 480px;
  }
}
