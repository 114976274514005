@include media-breakpoint-up(md) {
  .button-catalogue {
    padding: 1.5px 40px !important;
    font-size: 24px !important;
  }

  .sample-count-container {
    position: fixed;
    bottom: 0;
    right: 0.5rem;
    z-index: 3;
  }

  .logo-color {
    filter: sepia(100%) hue-rotate(190deg) saturate(900%) !important;
  }
}
