@include media-breakpoint-up(md) {
  .home-slide {
    padding-bottom: 60%;
  }

  .home-slide__content {
    width: 100%;
    height: auto;
    position: absolute;
  }

  .home-slide__content--left {
    left: 0;
    bottom: 0;
    padding-bottom: 6rem;
  }

  .home-slide__content--right {
    left: 50%;
    bottom: 35%;
    padding-bottom: 6rem;
  }

  .home-slide__content--center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slide-content__title_home {
    font-size: $h1-font-size * 3;
  }
}
