.btn-base {
  background-color: initial;
  cursor: pointer;
  display: inline-block;
  padding: 0.375rem 0.75rem !important;
  border-radius: 0.875rem !important;
  border: 1px solid transparent;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;

  font-family: $font-family-base;
  font-size: $font-size-base !important;
  font-weight: $font-weight-medium;
  line-height: 1.5;

  i {
    position: relative;
    top: 1px;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// Quick monkey patch for btn-outline-dark because it's hiding text when hovered
.btn-outline-dark {
  &:hover {
    color: white !important;
  }
}

.btn-outline-claims--radio {
  width: 18vw;
  border-radius: 0;
  border-color: $gray;
}

@include media-breakpoint-down(md) {
  .btn-outline-claims--radio {
    width: 40vw;
  }
}

.btn-check:checked + .btn-outline-claims--radio {
  background-color: $gray-400;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

.btn-primary {
  color: white;
}

.btn-dark {
  &:disabled,
  &.disabled {
    opacity: 0.5;
  }
}

.btn-sm {
  font-size: $font-size-xs !important;
}
