@include media-breakpoint-down(md) {
  .fabric-img {
    height: 250px;
  }

  .description-container {
    height: auto;
  }

  .image-backgrond-inspired {
    height: 250px;
  }

  .spirit-brackground {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  }

  .text-spirit-container {
    z-index: 99;
  }

  .description-container {
    position: relative;
    height: 250px;
  }
}
