@include media-breakpoint-down(md) {
  .feature-img {
    width: 40%;
  }

  .application-container {
    margin: 0 0.5rem;
  }

  .application-img {
    width: 24px;
    height: 24px;
  }

  .description-feature {
    font-size: 0.5rem;
  }
}
