.chat-bubble {
  height: 20px;
  opacity: 0;
  margin: 0;
  position: relative;
  right: 30px;
  animation: fadeAndSlide 0.3s ease forwards;
  background-color: #dee9ee !important;
}

.sample-count-container {
  position: fixed;
  bottom: 0;
  right: 8%;
  z-index: 1;
}

.logo-color {
  filter: sepia(100%) hue-rotate(190deg) saturate(900%) !important;
}

@keyframes fadeAndSlide {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
