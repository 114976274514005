@include media-breakpoint-up(lg) {
  .hero-links {
    width: 100%;

    &:before {
      width: $hero-bg-width-lg;
    }

    ul {
      li {
        button {
          font-size: 13px;
        }
      }
    }
  }
}
