.hero {
  display: flex;
  align-items: flex-end;
  width: 100vw;
  height: 100vh;
  color: $white;
}

.hero-description-container {
  margin: 0 4rem;
  margin-bottom: 14rem;
  z-index: 1;

  .description-text {
    margin-bottom: 2rem;
    font-size: $font-size-lg;
  }
}

.design-navigator {
  position: absolute;
  bottom: 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  z-index: 1;

  .design-link {
    margin: 0 4.15rem;
    font-size: 1.75rem;
    cursor: pointer;

    position: relative; // Hace que el elemento ::after sea relativo a este elemento

    &.underlined-active::after {
      content: ""; // Agrega un contenido al elemento ::after
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px; // Altura de la barra
      background-color: #fff; // Color de la barra
    }
  }
}

.videoembed {
  width: 100%;
  height: 100vh;
  padding: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
}
