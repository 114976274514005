.feature-img {
  width: 50%;
}

.application-container {
  margin: 0 1.25rem;
  text-align: center;
}

.application-img {
  width: 48px;
  height: 48px;
}

.description-feature {
  margin: 0.5rem 0;
  font-size: 0.75rem;
  white-space: pre-line;
}
