@include media-breakpoint-down(sm) {
  .slide-content__title_home {
    font-size: $h1-font-size * 1.2;
  }

  .slider-container {
    padding-top: 2.5rem;
  }

  .home-slide {
    height: 70vh;
  }

  .home-slide__content {
    height: 100%;
    position: relative;

    > .container {
      height: 100%;

      > .row {
        height: 100%;

        > [class*="col"] {
          height: 100%;
        }
      }
    }
  }

  .slide-content {
    position: absolute;
  }

  .slide-content--left {
    bottom: 0;
    padding: 0 1rem 4rem 1rem;
  }

  .slide-content--right {
    bottom: 0;
    padding: 0 1rem 4rem 1rem;
  }

  .slide-content--center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
