.main-container-history {
  margin-top: 100px;
}

.container-image {
  height: 400px;
}

.video-container {
  height: 200px;
}

.image-backgrond-inspired {
  height: 480px;
  background-image: url("../img/wasabi-inspired.jpg");
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}

.image-content {
  height: 200px;
}

.spacing-bottom {
  margin-bottom: 100px;
}

.fabric-img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}

.spirit-brackground {
  position: relative;
  background-image: url("../../../modules/ComingSoonPage/img/background.jpg");
  background-size: cover;
  color: white;
}
