@include media-breakpoint-only(md) {
  .play-icon {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 70px !important;
    height: 70px !important;
    color: $platinum;
    z-index: 1;
    cursor: pointer;
  }
}