@include media-breakpoint-up(xl) {
  .play-icon {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 96px;
    height: 96px;
    color: $platinum;
    z-index: 1;
    cursor: pointer;
  }
}
