@font-face {
  font-family: "Dala Moa";
  src: url("DalaMoa-Medium.woff2") format("woff2"), url("DalaMoa-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Dala Moa";
  src: url("DalaMoa-Bold.woff2") format("woff2"), url("DalaMoa-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
