.Toolbar {
  // background-color: $white;
  z-index: 5;
  width: 100%;
  position: fixed;
  padding: 0;
  transition: background-color 100ms linear 120ms;
}

.logo {
  width: 160px;
}

.upholstery-glassmorphism {
  -webkit-backdrop-filter: blur(0.5rem);
  backdrop-filter: blur(0.5rem);
  background: hsla(0, 0%, 100%, 0.25);
}

li {
  color: #a8a8a8;
  list-style: none;
  position: relative;
  padding-left: 50px;
  line-height: 2;
  font-size: 32px;
}

.hover-white:hover {
  color: #fff !important;
}

.hover-black:hover {
  color: #000000 !important;
}

.animation-link {
  font-size: 2em;
  transition: all 0.3s ease-out;
  display: inline-block;
}

.animation-link:hover {
  transform: translate(0, -5px);
}

.bg-header {
  background-color: rgba(255, 255, 255, 0.568) !important;
}

.font-story {
  font-size: 14px; // 24 px
  font-family: $font-family-title;
  text-decoration: none;
}
